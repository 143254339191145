import { IdentifyFn, PageFn, ResetFn, TrackFn } from "..";

let identified = false;

const identify: IdentifyFn = (user) => {
  identified = true;
  // @ts-ignore
  window.Intercom("update", {
    user_id: user.uid,
    user_hash: user.intercomUserHash || undefined,
  });
};

const track: TrackFn = (event, ...args) => {
  if (event === "auth_login") {
    // Not needed
    return;
  }

  if (event === "generic_addToFavorites") {
    // Tracked on the server
    return;
  }

  // @ts-ignore
  window.Intercom("trackEvent", event, args?.[0]);
};

const page: PageFn = () => {
  // @ts-ignore
  window.Intercom("update");
};

const reset: ResetFn = () => {
  if (identified) {
    // @ts-ignore
    window.Intercom("shutdown");
    identified = false;
  }
};

export function hideIntercomLauncher() {
  // @ts-ignore
  window.Intercom("update", {
    hide_default_launcher: true,
  });
}

export function showIntercomLauncher() {
  // @ts-ignore
  window.Intercom("update", {
    hide_default_launcher: false,
  });
}

export default {
  identify,
  reset,
  track,
  page,
};
