"use client";

import { createElement, forwardRef, FunctionComponent, ReactNode } from "react";
import { useLingui } from "@lingui/react";
import clsx from "clsx";
import { isEmpty, uniq } from "lodash";

import { DisciplineDefaultPicture, EventPreviewData } from "@kavval/constants";
import { createCloudinaryURL, getFlagUrl } from "@kavval/formatters";
import { type Locale } from "@kavval/lingui-config/locales";
import RacePathIcon from "@kavval/ui/OutlinedIcons/RacePathIcon";

import Discipline from "../Discipline";
import EventDate from "../EventDate";
import ImageWithContent from "../ImageWithContent";
import Skeleton from "../Skeleton";
import Distances from "./Distances";
import RaceTags from "./RaceTags";
import styles from "./style.module.css";

const getEventPicture = (event: Pick<EventPreviewData, "image" | "raceDiscipline">) => {
  return createCloudinaryURL(
    // eslint-disable-next-line
    event.image || DisciplineDefaultPicture[event.raceDiscipline!],
    ["h_250", "w_250", "g_auto", "c_lfill", "q_auto:low"]
  );
};

const skeletonContent = (
  <div className={styles.EventPreview}>
    <Skeleton
      height="100%"
      containerClassName={clsx(styles.PictureWrapper, styles.PictureSkeleton)}
    />
    <div className={styles.Content}>
      <Skeleton width="68%" />
      <Skeleton width="92%" />
      <Skeleton width="52%" />
      <Skeleton width="48%" />
    </div>
  </div>
);

type EventPreviewBaseProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: FunctionComponent<any> | string;
  highlights?: {
    tags?: string | string[] | null;
    distance?: { min?: number; max?: number };
  };
  fixedHeight?: boolean;
  actions?: ReactNode;
};

type EventPreviewProps = EventPreviewBaseProps &
  (
    | {
        skeleton?: false | undefined | null;
        event: EventPreviewData;
        href: string;
      }
    | {
        skeleton: true;
        event?: never;
        href?: never;
      }
  );

const EventPreview = forwardRef<HTMLAnchorElement, EventPreviewProps>(
  (
    {
      component = "a",
      highlights = {},
      event,
      fixedHeight,
      skeleton,
      actions,
      href,
      ...otherProps
    },
    ref
  ) => {
    const { i18n } = useLingui();
    const locale = i18n.locale as Locale;

    if (skeleton) return skeletonContent;

    const cityName = event[`city_${locale}`] || event.city;
    const countryName = event[`country_${locale}`] || event.country;

    const eventName = event[`eventName_${locale}`] || event.eventName;
    const boosted = event.boosted;
    const disciplineVariants = uniq(
      (event.raceDisciplineVariants || []).filter((d) => d !== event.raceDiscipline)
    );

    return createElement(
      component,
      {
        className: clsx(
          styles.EventPreview,
          fixedHeight ? styles.FixedHeight : null,
          boosted && styles.EventPreviewBoosted,
          "group no-link-underline"
        ),
        // other props peut contenir onClick, onMouseEnter, onTouchStart (ajoutés par next/link)
        ...otherProps,
        ref,
        href,
      },
      <>
        <ImageWithContent
          imageSrc={skeleton ? "" : getEventPicture(event)}
          wrapperClassName={clsx(styles.PictureWrapper, "group-hover:shadow-effect")}
          innerClassName={styles.PictureContent}
        >
          <div className={clsx(styles.Tags, "nice-scrollbar")}>
            {!isEmpty(event.tags) || boosted ? (
              <RaceTags tags={event.tags} highlights={highlights.tags} boost={boosted} />
            ) : null}
          </div>
          <div className={styles.Disciplines}>
            {event.raceDiscipline ? (
              <>
                <span className={clsx(styles.Discipline, "arch arch-up arch-sm")}>
                  <Discipline discipline={event.raceDiscipline} />
                </span>
                {disciplineVariants.length >= 1 ? (
                  <>
                    <span
                      className={clsx(
                        styles.Discipline,
                        styles.PlusDisciplines,
                        "arch arch-up arch-sm"
                      )}
                    >
                      +{disciplineVariants.length}
                    </span>
                    <span className={styles.OtherDisciplines}>
                      {disciplineVariants.map((discipline) => (
                        <span
                          className={clsx(styles.Discipline, "arch arch-up arch-sm")}
                          key={discipline}
                        >
                          <Discipline discipline={discipline} />
                        </span>
                      ))}
                    </span>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </ImageWithContent>
        <div className={styles.Content}>
          {/* <div className={styles.FavIcon} data-intercom-target="like-button">
            <FavButton iconOnly eventId={event.eventId} />
          </div> */}
          <EventDate
            className={clsx(styles.Date, "py-1")}
            isRescheduled={event.editionIsRescheduled}
            endDate={event.editionEndDate}
            startDate={event.editionStartDate}
            status={event.editionStatus}
          />
          <div className="typo-h4 leading-tight mb-1">{eventName}</div>
          {cityName && event.countryCode ? (
            <div className={styles.City}>
              <img
                src={getFlagUrl(event.countryCode, 32)}
                width="16"
                height="12"
                loading="lazy"
                title={countryName || `${event.countryCode}`}
              />
              <span>{cityName}</span>
            </div>
          ) : null}
          <div className={styles.Distances}>
            <div className="flex justify-center items-center">
              <RacePathIcon className="h-full flex justify-center items-center" />
            </div>
            <Distances
              disciplineVariants={event.raceDisciplineVariants}
              distanceVariants={event.raceDistanceVariants}
              unitVariants={event.raceDistanceUnitVariants}
              categoryVariants={event.raceCategoryVariants}
              highlights={highlights.distance}
            />
          </div>
          <div className={styles.Actions}>{actions}</div>
        </div>
      </>
    );
  }
);

EventPreview.displayName = "EventPreview";

export default EventPreview;
