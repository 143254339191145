type ErrorContext = ConstructorParameters<typeof OfferSoldOutError>[0];

export enum OrderErrorCodeEnum {
  SOLD_OUT = "OFFER_SOLD_OUT",
  EXPIRED = "OFFER_EXPIRED",
  NOT_OPENED = "OFFER_NOT_OPENED",
  UNAVAILABLE = "OFFER_UNAVAILABLE",
}

export class OrderError extends Error {
  public type = "OrderError";
  public context: ErrorContext;
  public code: OrderErrorCodeEnum;
  // Pour graphql...
  public extensions: { context: ErrorContext; code: OrderErrorCodeEnum };

  constructor(code: OrderErrorCodeEnum, context: ErrorContext) {
    super(code);
    // Les propriétés suivantes seront sérialisées si on fait JSON.stringify(err)
    this.code = code;
    this.context = context;

    this.extensions = {
      code: code,
      context: context,
    };
  }
}

export class OfferSoldOutError extends OrderError {
  constructor(context: { offerId: string }) {
    super(OrderErrorCodeEnum.SOLD_OUT, context);
  }
}

export class OfferExpiredError extends OrderError {
  constructor(context: { offerId: string }) {
    super(OrderErrorCodeEnum.EXPIRED, context);
  }
}

export class OfferNotOpenedError extends OrderError {
  constructor(context: { offerId: string }) {
    super(OrderErrorCodeEnum.NOT_OPENED, context);
  }
}

export class OfferUnaivailableError extends OrderError {
  constructor(context: { offerId: string }) {
    super(OrderErrorCodeEnum.UNAVAILABLE, context);
  }
}
