// import styles from "./styles.module.css";

const OpenIntercomLink = ({ message = "", children }) => {
  return (
    <span
      className="font-semibold underline cursor-pointer"
      onClick={() => {
        window.Intercom("showNewMessage", message);
      }}
    >
      {children}
    </span>
  );
};

export default OpenIntercomLink;
