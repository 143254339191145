import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { LoadingCentered, PageWrapper } from "@booking/components";
import Booking from "@booking/pages/Booking";
import Confirmation from "@booking/pages/Confirmation";
import EventRaces from "@booking/pages/EventRaces";
import Test from "@booking/pages/Test";
import WaitingList from "@booking/pages/WaitingList";

const RedirectHome = () => {
  useEffect(() => {
    window.location.href = "/";
  });
  return null;
};

function App() {
  return (
    <React.Suspense
      fallback={
        <PageWrapper>
          <LoadingCentered />
        </PageWrapper>
      }
    >
      <Routes>
        <Route path="/test-njuko2/*" element={<Test />} />
        <Route path="/event/:eventShortId/*" element={<EventRaces />} />
        <Route path="/ticket/:ticketShortId/*" element={<Booking />} />
        <Route path="/order/:orderShortId/*" element={<Confirmation />} />
        <Route path="/waiting-list/:raceEditionId/*" element={<WaitingList />} />
        <Route path="/*" element={<RedirectHome />} />
      </Routes>
    </React.Suspense>
  );
}

export default App;
