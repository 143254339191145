// import styles from "./styles.module.css";

import { ComponentProps } from "react";
import { t, Trans } from "@lingui/macro";

import NotFoundMessage from "@booking/components/NotFoundMessage";
import OpenIntercomLink from "@booking/components/OpenIntercomLink";

const GenericNotFoundError = ({
  tracking,
}: {
  tracking?: ComponentProps<typeof NotFoundMessage>["tracking"];
}) => {
  return (
    <NotFoundMessage
      title={t({
        id: "page.ticket.error.loading.title",
        message: "Notre robot s'est foulé la cheville...",
      })}
      tracking={tracking}
    >
      <p>
        <Trans id="page.ticket.error.loading.message.p1">
          Une erreur est survenue lors du chargement de la page. Nos équipes ont été alertées et
          courent résoudre ce problème.
        </Trans>
      </p>
      <p>
        <Trans id="page.ticket.error.loading.message.p2">
          N'hésite pas à <OpenIntercomLink>nous contacter</OpenIntercomLink> pour prendre des
          nouvelles du robot.
        </Trans>
      </p>
      <p>
        <a className="button button-primary mt-8" href="/">
          <Trans id="page.ticket.error.notFound.backToHomePageButton">Revenir à l'accueil</Trans>
        </a>
      </p>
    </NotFoundMessage>
  );
};

export default GenericNotFoundError;
