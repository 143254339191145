import * as Sentry from "@sentry/react";
import { browserTracingIntegration } from "@sentry/react";
import React from "react";
import { ApolloProvider } from "@apollo/client";
import { I18nProvider } from "@lingui/react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import "virtual:vite-plugin-sentry/sentry-config";

import { init as initCookieConsent } from "@kavval/cookieconsent";

import { getTrpcClient, trpc } from "@booking/lib/trpc";

import "@kavval/design-tokens/variables.css";
import "@kavval/cookieconsent/style.css";

import apolloClient from "@booking/lib/graphql";
import i18n, { initLocale } from "@booking/lib/lingui";

import App from "./App";

import "./index.css";

import muiTheme from "@kavval/mui-theme";

import { ENV_VARS } from "@booking/utils/env-vars";

import { queryClient } from "./lib/tanstack-query/client";
import reportWebVitals from "./reportWebVitals";

if (ENV_VARS.IS_PROD) {
  Sentry.init({
    dsn: ENV_VARS.SENTRY_DSN,
    integrations: [browserTracingIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,

    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /tuseja\.pacoyixo\.com/i,
    ],
    dist: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist,
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
  });
}

// @ts-ignore
window._testSentry = () => {
  setTimeout(() => {
    throw new Error("BOOM TEST SENTRY");
  }, 1000);
};

const container = document.getElementById("root")!;
const root = createRoot(container);

initLocale().then(() => {
  console.log(i18n.locale);
  initCookieConsent();
});

root.render(
  <React.StrictMode>
    <I18nProvider i18n={i18n}>
      <trpc.Provider client={getTrpcClient()} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
              <Router basename="/book">
                <ApolloProvider client={apolloClient}>
                  <App />
                </ApolloProvider>
              </Router>
            </ThemeProvider>
          </StyledEngineProvider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
        </QueryClientProvider>
      </trpc.Provider>
    </I18nProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
