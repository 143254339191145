import { ComponentProps, FC, PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";

import Label from "../Label";

type FormGroupProps = {
  error?: ReactNode | boolean;
  label?: ComponentProps<typeof Label>["label"];
  rightLabel?: ComponentProps<typeof Label>["rightLabel"];
  id?: string;
  helperText?: ReactNode;
  className?: string;
};

const FormGroup: FC<PropsWithChildren<FormGroupProps>> = ({
  error,
  label,
  rightLabel,
  helperText,
  id,
  children,
  className,
}) => {
  return (
    <div className={clsx("form-group", !!error && "form-error", className)}>
      {label && <Label label={label} htmlFor={id} rightLabel={rightLabel} />}
      {children}
      {helperText && <div className="form-help-text">{helperText}</div>}
      {error && typeof error !== "boolean" ? <div className="form-help-text">{error}</div> : null}
    </div>
  );
};

export default FormGroup;
