/* eslint-disable no-irregular-whitespace */
import { forwardRef, HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";

interface Props {
  label: string | ReactNode;
  open?: boolean;
  disabled?: boolean;
}

const SelectButton = forwardRef<HTMLDivElement, Props & HTMLAttributes<HTMLDivElement>>(
  ({ label, className, disabled, open, ...props }, ref) => (
    <div
      tabIndex={0}
      className={clsx(
        // On utilise "inline-flex" car "inline-block" ajoute des espaces avec "overflow:hidden" ("truncate")
        "form-select inline-flex min-w-[100px] max-w-full",
        disabled && "form-input-disabled",
        className
      )}
      ref={ref}
      aria-expanded={props["aria-expanded"] || open}
      aria-disabled={props["aria-disabled"] || disabled}
      {...props}
    >
      <span className="truncate">{label || " "}</span>
    </div>
  )
);

SelectButton.displayName = "SelectButton";

export default SelectButton;
