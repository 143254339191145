/* eslint react/jsx-no-target-blank:0 */

import { ComponentProps, useCallback } from "react";
import { Trans } from "@lingui/macro";

import { LocaleSwitcher } from "@kavval/ui";

import { CardPaymentLogos, Container } from "@booking/components";
import client from "@booking/lib/graphql";
import { initLocale } from "@booking/lib/lingui";

const year = new Date().getFullYear();

const Footer = () => {
  const onLocaleChange = useCallback<
    ComponentProps<typeof LocaleSwitcher>["onChange"]
  >(async () => {
    await initLocale(true);
    await client.refetchQueries({ include: "all" });
  }, []);

  return (
    <div className="border-t border-dark-blue/5 bg-white mt-10 py-4 shrink-0">
      <Container className="flex flex-col md:flex-row md:items-center justify-between ">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-6">
          <CardPaymentLogos />
          <LocaleSwitcher onChange={onLocaleChange} />
        </div>
        <div className="inline-block text-sm pt-4 md:pt-0 md:pr-14 2xl:pr-0 text-storm-grey">
          {/* eslint-disable-line lingui/no-unlocalized-strings */}© Finishers {year}
          {" | "}
          <a href="/docs/legal-notice" className="hover:text-fjord-grey" target="_blank">
            <Trans id="component.footer.legalMentions">Mentions légales</Trans>
          </a>
          {" | "}
          <a href="/docs/terms-of-sale" className="hover:text-fjord-grey" target="_blank">
            <Trans id="component.footer.termsOfSale">C.G.V.</Trans>
          </a>
          {" | "}
          <a href="/docs/privacy-policy" className="hover:text-fjord-grey" target="_blank">
            <Trans id="component.footer.privacy">Cookies</Trans>
          </a>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
