import { useEffect } from "react";
import { pick } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { GetInitialDataQuery } from "@booking/graphql/graphql";
import { BookingRoutePathEnum } from "@booking/pages/Booking";

const saveState = (state: BookingState) => {
  try {
    localStorage.setItem(
      `ticket:${state.ticketShortId}`,
      JSON.stringify(pick(state, ["orderId", "user", "detailsForm"]))
    );
  } catch (e) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    console.warn("could not save state to localStorage");
  }
};

export const clearLocalStorage = (ticketShortId: string) => {
  try {
    localStorage.removeItem(`ticket:${ticketShortId}`);
  } catch (e) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    console.warn("could not clear localStorage");
  }
};

export const stateInitializer = (initialState: Partial<BookingState>) => {
  const { ticketShortId } = initialState;
  let savedState = {} as BookingState;

  try {
    // @ts-ignore
    savedState = JSON.parse(localStorage.getItem(`ticket:${ticketShortId}`)) as BookingState;
  } catch (e) {
    // nothing
  }

  const newState: BookingState = {
    user: undefined,
    ticket: undefined,
    event: undefined,
    race: undefined,
    raceEdition: undefined,
    // @ts-ignore
    detailsForm: null,
    // @ts-ignore
    currentStep: BookingRoutePathEnum.Auth,
    ...initialState,
    ...savedState,
    orderId: savedState?.orderId || uuidv4(),
  };

  if (newState.user) {
    newState.currentStep = BookingRoutePathEnum.Details;
  }

  saveState(newState);

  return newState;
};

export const useStateLocalStorage = (state: BookingState) => {
  useEffect(() => {
    saveState(state);
  }, [state]);
};

enum ActionType {
  SET_USER = "SET_USER",
  SET_TICKET = "SET_TICKET",
  CONFIRM_DETAILS = "CONFIRM_DETAILS",
  SAVE_DETAILS = "SAVE_DETAILS",
  RESET_ORDER_ID = "RESET_ORDER_ID",
}

export const PROFILE_FIELDS = ["firstName", "lastName", "email", "phone", "birthdate", "genre"];

type QueryResult = NonNullable<GetInitialDataQuery>;
export type User = NonNullable<QueryResult["me"]>;
type Ticket = NonNullable<QueryResult["ticketByShortId"]>;
type RaceEdition = NonNullable<Ticket["raceEdition"]>;
type Race = NonNullable<RaceEdition["race"]>;
type Event = NonNullable<Race["event"]>;

export type BookingState = {
  ticketShortId: string;
  orderId: string;
  user?: User;
  ticket?: Omit<Ticket, "raceEdition">;
  event?: Event;
  race?: Omit<Race, "event">;
  raceEdition?: Omit<RaceEdition, "race">;
  // FIXME later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detailsForm: any;
  currentStep: BookingRoutePathEnum;
};

// Action creators
export const setUser = (payload?: User) => ({ type: ActionType.SET_USER, payload });
export const setTicket = (payload: Ticket) => ({ type: ActionType.SET_TICKET, payload });
export const confirmDetails = (payload: BookingState["detailsForm"]) => ({
  type: ActionType.CONFIRM_DETAILS,
  payload,
});
export const saveDetails = (payload: BookingState["detailsForm"]) => ({
  type: ActionType.SAVE_DETAILS,
  payload,
});
export const resetOrderId = () => ({ type: ActionType.RESET_ORDER_ID, payload: undefined });

// Selectors
export const isAppLoaded = (state: BookingState) => typeof state.event !== "undefined";
export const isAuthenticated = (state: BookingState) => state?.user;
export const isAnonymous = (state: BookingState) => !!state.user?.isAnonymous;
export const isAffiliation = (state: BookingState) =>
  !!(state.ticket?.sellerId && /affiliation/.test(state.ticket?.sellerId));

export type BookingAction = ReturnType<
  | typeof setUser
  | typeof setTicket
  | typeof confirmDetails
  | typeof saveDetails
  | typeof resetOrderId
>;

const currentStepReducer = (state: BookingState, { type, payload }: BookingAction) => {
  if (type === ActionType.SET_USER) {
    return !payload ? BookingRoutePathEnum.Auth : BookingRoutePathEnum.Details;
  }
  if (type === ActionType.CONFIRM_DETAILS) {
    return BookingRoutePathEnum.Payment;
  }

  return state.currentStep;
};

export const reducer = (state: BookingState, { type, payload }: BookingAction) => {
  if (type === ActionType.RESET_ORDER_ID) {
    return {
      ...state,
      orderId: uuidv4(),
    } satisfies BookingState;
  }
  if (type === ActionType.SET_USER) {
    const newState: BookingState = { ...state };

    if (!payload) {
      newState.user = undefined;
      newState.currentStep = currentStepReducer(state, { type, payload });
      newState.detailsForm = null;
    } else {
      newState.user = payload;

      if (state.user?.id && state.user?.id !== newState.user?.id) {
        // Le cas peut se produire lorsqu'on commence une résa,
        // puis qu'on se déconnecte et reconnecte avec un autre compte.
        // Dans ce cas on réinitialise les données du formulaire ainsi que le numéro de commande.
        newState.orderId = uuidv4();
        delete newState.detailsForm;
      }

      newState.currentStep = currentStepReducer(state, { type, payload });

      if (!state.detailsForm) {
        newState.detailsForm = {
          saveParticipant: true,
          participant: "me",
          participantDetails: pick(payload, [...PROFILE_FIELDS, "newsletter"]),
          assurance: "",
        };
      }

      // if (payload.isAnonymous) {
      //   newState.detailsForm.participant = "me";
      // }
    }

    return newState;
  }
  if (type === ActionType.SET_TICKET) {
    const p = payload as Ticket;
    return {
      ...state,
      ticket: p,
      event: p.raceEdition?.race?.event,
      raceEdition: p.raceEdition!,
      race: p.raceEdition?.race,
    } satisfies BookingState;
  }
  if (type === ActionType.CONFIRM_DETAILS) {
    return {
      ...state,
      detailsForm: payload as BookingState["detailsForm"],
      currentStep: currentStepReducer(state, { type, payload }),
    } satisfies BookingState;
  }
  if (type === ActionType.SAVE_DETAILS) {
    return {
      ...state,
      detailsForm: payload as BookingState["detailsForm"],
    };
  }

  return state;
};
