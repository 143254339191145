/* eslint-disable no-irregular-whitespace */

import { t, Trans } from "@lingui/macro";

import { OfferStatusEnum } from "@api/db/models/Offer/status";
import { OrderErrorCodeEnum } from "@api/db/models/Order/errors";

import { ErrorBlock, OpenIntercomLink } from "@booking/components";
import OrderErrorMessage from "@booking/components/OrderErrorMessage";

export const isOfferErrorCode = (error: string) => {
  return (
    Object.values(OrderErrorCodeEnum).includes(error as OrderErrorCodeEnum) ||
    [
      OfferStatusEnum.RegistrationNotOpened,
      OfferStatusEnum.SoldOut,
      OfferStatusEnum.Expired,
    ].includes(error as OfferStatusEnum)
  );
};

const RegistrationError = ({
  isFree,
  order,
  error,
  eventShortId,
  className,
}: {
  isFree: boolean;
  eventShortId: string;
  order?: {
    shortId?: string;
    id?: string;
  };
  error?: string;
  className?: string;
}) => {
  const intercomErrorMessage = t({
    id: "page.payment.registrationError.intercomPrePopulatedErrorMessage",
    message: `Bonjour, je n'arrive pas à réserver mon dossard, il y a une erreur lors de la réservation auprès de l'organisateur. Mon numéro de commande est "${
      order?.shortId || order?.id
    }".`,
  });

  const offerError = error && isOfferErrorCode(error);

  return (
    <ErrorBlock
      title={
        offerError
          ? t({
              id: "page.payment.offerError.title",
              message: "Une erreur est survenue :",
            })
          : t({
              id: "page.payment.registrationError.title",
              message:
                "Une erreur est survenue lors de la réservation du dossard auprès de l'organisateur 🤕",
            })
      }
      className={className}
    >
      {error && /(Duplicate registration error|DUPLICATE_REGISTRATION)/i.test(error) && (
        <p className="mt-2">
          <Trans id="page.payment.duplicateRegistrationError.p1">
            Il semble qu'une réservation ait déjà été faite pour cette personne sur cette course
            (même nom et date de naissance). N'hésite pas à{" "}
            <OpenIntercomLink message={intercomErrorMessage}>nous contacter</OpenIntercomLink> si ce
            n'est pas le cas.
          </Trans>
        </p>
      )}
      {error && offerError ? (
        <OrderErrorMessage
          code={error}
          eventShortId={eventShortId}
          paragraphClassName="mt-2"
          // eslint-disable-next-line lingui/no-unlocalized-strings
          linkClassName="mt-6 button button-primary"
        />
      ) : null}
      {!error && (
        <>
          <p className="mt-2">
            <Trans id="page.payment.registrationError.p1">
              Nos équipes ont été alertées et courent résoudre ce problème. N'hésite pas à{" "}
              <OpenIntercomLink message={intercomErrorMessage}>nous contacter</OpenIntercomLink>{" "}
              pour obtenir plus d'informations.
            </Trans>
          </p>
          <p className="mt-2">
            <Trans id="page.payment.registrationError.p2">
              Toutes nos excuses pour ce désagrément.
            </Trans>
          </p>
        </>
      )}
      {!isFree && (
        <p className="mt-2">
          <Trans id="page.payment.registrationError.p3">
            <b>NB :</b> Aucun prélèvement n'a été réalisé sur ton moyen de paiement.
          </Trans>
        </p>
      )}
    </ErrorBlock>
  );
};

export default RegistrationError;
