export const emojiMap = {
  "beach-with-umbrella": "beach-with-umbrella_1f3d6-fe0f",
  cloud: "cloud_2601-fe0f",
  "cloud-with-lightning-and-rain": "cloud-with-lightning-and-rain_26c8-fe0f",
  "cloud-with-rain": "cloud-with-rain_1f327-fe0f",
  "cloud-with-snow": "cloud-with-snow_1f328-fe0f",
  "fallen-leaf": "fallen-leaf_1f342",
  map: "Map",
  "world-map": "world-map_1f5fa-fe0f",
  road: "Road",
  "running-shoe": "running-shoe_1f45f",
  "running-shoe-2": "shoe-emoji.svg",
  "santa-claus": "santa-claus_light-skin-tone_1f385-1f3fb_1f3fb",
  "snowman-without-snow": "snowman-without-snow_26c4",
  "sports-medal": "sports-medal_1f3c5",
  sun: "sun_2600-fe0f",
  "sun-behind-cloud": "sun-behind-cloud_26c5",
  "sun-behind-rain-cloud": "sun-behind-rain-cloud_1f326-fe0f",
  thermometer: "thermometer_1f321-fe0f",
  "umbrella-with-rain-drops": "umbrella-with-rain-drops_2614",
  mountain: "mountain_26f0-fe0f",
  "nordic-walking-sticks": "nordic-walking-sticks",
  "nordic-walking-sticks-2": "sticks.svg",
  runner: "person-running_1f3c3",
  "runner-2": "runner.svg",
  "mountain-2": "mountain.svg",
  "thumbs-up": "thumbs-up_1f44d",
  "hourglass-not-done": "hourglass-not-done_23f3",
  trophy: "trophy_1f3c6",
  baby: "baby_1f476",
  "children-crossing": "children-crossing_1f6b8",
  "face-screaming-in-fear": "face-screaming-in-fear_1f631",
  "person-shrugging": "person-shrugging_1f937",
  cityscape: "cityscape_1f3d9-fe0f",
  "desert-island": "desert-island_1f3dd-fe0f",
  "flag-france": "flag-france_1f1eb-1f1f7",
  "snow-capped-mountain": "snow-capped-mountain_1f3d4-fe0f",
  "star-struck": "star-struck_1f929",
  construction: "construction_1f6a7",
};

export const getEmojiCloudinaryURL = (name: keyof typeof emojiMap): string =>
  `app/emojis/${emojiMap[name]}`;
