import { useCallback } from "react";
import { gql, useApolloClient } from "@apollo/client";

const SAVE_MY_PROFILE = gql`
  mutation SaveMyProfile($profile: ProfileInput!) {
    saveMyProfile(profile: $profile) {
      id
    }
  }
`;

export type ProfileFields = {
  firstName: string;
  lastName: string;
  genre: "f" | "m";
  email: string;
  phone: string;
  birthdate: string;
  newsletter: boolean;
};

const useSaveProfile = () => {
  const client = useApolloClient();

  const saveProfile = useCallback(
    async (profile: Partial<ProfileFields>) => {
      return await client.mutate({
        mutation: SAVE_MY_PROFILE,
        variables: {
          profile,
        },
      });
    },
    [client]
  );

  return saveProfile;
};

export default useSaveProfile;
