import Analytics from "@kavval/analytics";
import criteo from "@kavval/analytics/criteo";
import facebook from "@kavval/analytics/facebook";
import google from "@kavval/analytics/google";
import intercom from "@kavval/analytics/intercom";
import plausible from "@kavval/analytics/plausible";
import wisepops from "@kavval/analytics/wisepops";

import { ENV_VARS } from "@booking/utils/env-vars";

import logrocket from "./logrocket";
import sentry from "./sentry";

const analytics = new Analytics(
  [sentry, google, logrocket, intercom, facebook, plausible, criteo, wisepops],
  {
    production: ENV_VARS.IS_PROD,
    plausibleApiUrl: `https://www.${ENV_VARS.KAVVAL_DOMAIN}/a7s`,
  }
);

export default analytics;
