import * as Sentry from "@sentry/react";
import React, { useEffect, useReducer } from "react";
import { gql, useQuery } from "@apollo/client";
import { t, Trans } from "@lingui/macro";
import { Link, Navigate, Route, Routes, useParams } from "react-router-dom";

import {
  GenericNotFoundError,
  LoadingCentered,
  NotFoundMessage,
  PageWrapper,
  ShoppingCart,
  ShoppingCartLayout,
} from "@booking/components";
import OrderErrorMessage from "@booking/components/OrderErrorMessage";
import { isOfferErrorCode } from "@booking/components/RegistrationError";
import { GetInitialDataQuery } from "@booking/graphql/graphql";
import analytics from "@booking/lib/analytics";
import useAuthState from "@booking/pages/Booking/Auth/useAuthState";
import NotFoundPage from "@booking/pages/NotFound";
import {
  isAppLoaded,
  reducer,
  setTicket,
  setUser,
  stateInitializer,
  useStateLocalStorage,
} from "@booking/state";

const Auth = React.lazy(() => import("@booking/pages/Booking/Auth"));
const Details = React.lazy(() => import("@booking/pages/Booking/Details"));
const Payment = React.lazy(() => import("@booking/pages/Booking/Payment"));

const GET_INITIAL_DATA = gql`
  query GetInitialData($ticketShortId: String!) {
    me {
      id
      email
      phone
      firstName
      lastName
      birthdate
      isAnonymous
      genre
      newsletter
      uid
      intercomUserHash
    }

    ticketByShortId(shortId: $ticketShortId) {
      id
      shortId
      description
      parentId
      fees
      price
      currency
      status
      availableAt
      expiresAt
      minBirthdate
      maxBirthdate
      maxRegistrations
      participantCount
      remainingRegistrations
      soldOnKavval
      sellerId

      raceEdition {
        date
        endDate
        time
        endTime
        status
        registrationUrl
        elevationGain
        elevationLoss
        activities {
          distance
          distanceUnit
          activity
        }
        distance
        distanceUnit

        edition {
          formFields
        }

        race {
          id
          name
          discipline

          event {
            id
            shortId
            name
            href
            slug
            bannerImage
            termsAndConditions

            city {
              name
            }
          }
        }
      }
    }
  }
`;

export enum BookingRoutePathEnum {
  Auth = "auth",
  Details = "details",
  Payment = "payment",
}

const Booking = () => {
  const { ticketShortId } = useParams();
  const [state, dispatch] = useReducer(
    reducer,
    { ticketShortId: ticketShortId! },
    stateInitializer
  );
  useStateLocalStorage(state);
  const { loading, error, data, refetch } = useQuery<GetInitialDataQuery>(GET_INITIAL_DATA, {
    variables: { ticketShortId },
  });
  const ticketFromQuery = data?.ticketByShortId;

  const { currentStep, ticket } = state;
  const activeRoutes: Array<BookingRoutePathEnum> =
    currentStep === BookingRoutePathEnum.Payment
      ? [BookingRoutePathEnum.Details, BookingRoutePathEnum.Payment]
      : [currentStep];
  const event = data?.ticketByShortId?.raceEdition?.race?.event;
  const eventShortId = event?.shortId;

  const { setNewsletterOptin } = useAuthState({ onChange: refetch });

  useEffect(() => {
    if (!loading) {
      if (!error) {
        if (ticketFromQuery) {
          dispatch(setTicket(ticketFromQuery)); // Needs to be set first (for now)
          // @ts-ignore later
          dispatch(setUser(data.me));
        }
      } else {
        Sentry.captureException(error);
        console.warn(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  useEffect(() => {
    if (state.user) {
      analytics.identify(state.user, true);
    }
  }, [state.user]);

  if (!loading) {
    if (error) {
      return (
        <PageWrapper>
          <GenericNotFoundError />
        </PageWrapper>
      );
    }

    if (!ticketFromQuery) {
      return <NotFoundPage pageType="book/checkout/not-found" />;
    }

    const { soldOnKavval, status } = ticketFromQuery;

    if (!soldOnKavval) {
      return <Navigate to={eventShortId ? `/event/${eventShortId}` : "/"} />;
    }

    if (isOfferErrorCode(status)) {
      return (
        <PageWrapper logoHref={event?.href}>
          <NotFoundMessage
            title={t({
              id: "page.ticket.error.notAvailable.title",
              message: "Offre non disponible",
            })}
            tracking={{ pageType: "book/checkout/not-opened" }}
          >
            <OrderErrorMessage
              code={status}
              eventShortId={eventShortId!}
              // eslint-disable-next-line lingui/no-unlocalized-strings
              linkClassName="button button-primary mt-8"
            />
          </NotFoundMessage>
        </PageWrapper>
      );
    }

    if (status === "sold_out" || status === "expired") {
      return (
        <PageWrapper logoHref={event?.href}>
          <NotFoundMessage
            title={t({
              id: "page.ticket.error.notAvailable.title",
              message: "Offre non disponible",
            })}
            tracking={{
              pageType: status === "sold_out" ? "checkout/sold-out" : "checkout/expired",
            }}
          >
            <p>
              <Trans id="page.ticket.error.notAvailable.message">
                Désolé, cette offre n'est plus disponible
              </Trans>
            </p>
            <p>
              <Link
                className="button button-primary mt-8"
                to={eventShortId ? `/event/${eventShortId}` : "/"}
              >
                <Trans id="page.ticket.error.notAvailable.seeOffersButton">
                  Voir toutes les offres
                </Trans>
              </Link>
            </p>
          </NotFoundMessage>
        </PageWrapper>
      );
    }
  }

  if (!isAppLoaded(state)) {
    return (
      <PageWrapper>
        <LoadingCentered />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper logoHref={event?.href}>
      <ShoppingCartLayout
        rightContent={
          <ShoppingCart
            registrations={[{ offer: ticketFromQuery! }]} /*assurance={detailsForm?.assurance}*/
          />
        }
        withSellingPoints={!ticket!.sellerId || !/affiliation/.test(ticket!.sellerId)}
      >
        <Routes>
          <Route
            path={BookingRoutePathEnum.Auth}
            element={
              !activeRoutes.includes(BookingRoutePathEnum.Auth) ? (
                <Navigate to={`/ticket/${ticketShortId}/${currentStep}`} replace />
              ) : (
                <Auth state={state} setNewsletterOptin={setNewsletterOptin} />
              )
            }
          />
          <Route
            path={BookingRoutePathEnum.Details}
            element={
              !activeRoutes.includes(BookingRoutePathEnum.Details) ? (
                <Navigate to={`/ticket/${ticketShortId}/${currentStep}`} />
              ) : (
                <Details state={state} dispatch={dispatch} />
              )
            }
          />
          <Route
            path={BookingRoutePathEnum.Payment}
            element={
              !activeRoutes.includes(BookingRoutePathEnum.Payment) ? (
                <Navigate to={`/ticket/${ticketShortId}/${currentStep}`} />
              ) : (
                <Payment state={state} dispatch={dispatch} />
              )
            }
          />
          <Route
            path="/"
            element={<Navigate to={`/ticket/${ticketShortId}/${currentStep}`} replace />}
          />
        </Routes>
      </ShoppingCartLayout>
    </PageWrapper>
  );
};

export default Booking;
