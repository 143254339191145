import { captureException } from "@sentry/react";
import { ApolloClient, ApolloLink, concat, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { getCurrentLocale } from "@booking/lib/lingui";
import { ENV_VARS } from "@booking/utils/env-vars";

import { getCurrentUser } from "./firebase";

const httpLink = new HttpLink({
  uri: `https://api.${ENV_VARS.KAVVAL_DOMAIN}/graphql`,
  credentials: "include",
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((e) => {
      captureException(e);
      console.log(e);
    });
  }
  if (networkError) {
    // ignore
  }
});

const authMiddleware = new ApolloLink(async (operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "accept-language": getCurrentLocale(),
    },
  }));

  try {
    const currentUser = await getCurrentUser();

    if (currentUser) {
      const token = await currentUser.getIdToken();
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      }));
    }
  } catch (e) {
    captureException(e);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    console.warn(`Error getting current user in apollo middleware`, e);
  }

  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, from([errorLink, httpLink])),
  cache: new InMemoryCache(),
});

export default client;
