"use client";

import { FC } from "react";
import { useLingui } from "@lingui/react";
import isArray from "lodash/isArray";

import { GenericDiscipline } from "@kavval/constants";
import { translateDiscipline } from "@kavval/formatters";

import DisciplineEmoji from "../DisciplineEmoji";
import styles from "./styles.module.css";

interface OneDisciplineProps {
  discipline: GenericDiscipline;
  iconSize?: number;
  showIcon?: boolean;
}

const OneDiscipline: FC<OneDisciplineProps> = ({ discipline, iconSize = 18, showIcon }) => {
  const { i18n } = useLingui();

  return (
    <span className={styles.Discipline}>
      {showIcon && (
        <span className={styles.Icon}>
          <DisciplineEmoji name={discipline} size={iconSize} />
        </span>
      )}
      <span>{translateDiscipline(i18n, discipline)}</span>
    </span>
  );
};

interface DisciplineProps {
  discipline: GenericDiscipline | GenericDiscipline[];
  separator?: string;
}

const Discipline: FC<DisciplineProps & OneDisciplineProps> = ({
  separator = ", ",
  discipline,
  ...props
}) => {
  if (isArray(discipline)) {
    return (
      <>
        {discipline.map((d) => <OneDiscipline key={d} {...props} discipline={d} />).join(separator)}
      </>
    );
  }

  return <OneDiscipline discipline={discipline} {...props} />;
};

export default Discipline;
