import { gql } from "@apollo/client";

import { SponsorsGrid } from "@kavval/ui";

import { SponsorGroup } from "@booking/graphql/graphql";

type SponsorsSectionProps = { sponsors: SponsorGroup[] };

export const SponsorsSectionFragment = gql`
  fragment SponsorsSectionFragment on Event {
    sponsors {
      title
      size
      items {
        name
        logo
        url
      }
    }
  }
`;

const SponsorsSection = ({ sponsors }: SponsorsSectionProps) => {
  return (
    <div className="py-14">
      <SponsorsGrid sponsors={sponsors} />
    </div>
  );
};

export default SponsorsSection;
