import { Trans } from "@lingui/macro";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { LogoHorizontal } from "@kavval/ui";
import { BellIcon } from "@kavval/ui/OutlinedIcons";

import { Container } from "@booking/components";

import styles from "./styles.module.css";

const NavBar = ({ logoHref = "/" }) => {
  return (
    <div
      className={clsx(
        styles.NavBar,
        "flex flex-col items-center justify-center bg-white border-b border-dark-blue/5"
      )}
    >
      <Container className="flex flex-row items-center justify-between">
        {/^\/(event|ticket|order)/.test(logoHref) ? (
          <Link to={logoHref}>
            <LogoHorizontal className={styles.Logo} />
          </Link>
        ) : (
          <a href={logoHref}>
            <LogoHorizontal className={styles.Logo} />
          </a>
        )}
        <div className="flex flex-row items-center justify-center leading-none text-xs md:text-base">
          <a
            tabIndex={0}
            role="button"
            aria-label="Notifications"
            href="#"
            className="wisp button icon-button"
          >
            <BellIcon />
          </a>
          <CheckRoundedIcon
            className={clsx(styles.CheckIcon, "text-electric-blue mr-1 !w-4 md:!w-6")}
          />
          <span
            className="text-electric-blue font-bold mr-2 md:mr-4"
            onClick={() => window.methodNotFound()}
          >
            {/* eslint-disable-line lingui/no-unlocalized-strings */}HTTPS
          </span>
          <span className="text-fjord-grey font-semibold mr-1">
            <Trans id="component.NavBar.securedPayment">Paiement sécurisé</Trans>
          </span>
          <LockOutlinedIcon className="text-electric-blue !w-4 md:!w-6" />
        </div>
      </Container>
    </div>
  );
};

export default NavBar;
