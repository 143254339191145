import { useCallback, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import { t } from "@lingui/macro";

import {
  LoadingCentered,
  PageTitle,
  ShoppingCartContainer,
  ShoppingCartLayout,
} from "@booking/components";
import GenericNotFoundError from "@booking/components/GenericNotFoundError";
import PageWrapper from "@booking/components/PageWrapper";
import useNjuko from "@booking/lib/hooks/useNjuko";

import "./njuko.css";

import NotFoundPage from "@booking/pages/NotFound";

const GET_EVENT = gql`
  query GetTestEvent($eventShortId: String!) {
    eventByShortId(shortId: $eventShortId) {
      id
      shortId
      name
      href
      bannerImage
      slug
      registrationUrl

      city {
        name
      }

      nextEdition {
        status
        startDate
        endDate

        raceEditions {
          id
          date
          endDate
          time
          endTime
          status
          registrationUrl
          elevationGain
          elevationLoss
          distance
          distanceUnit
          race {
            id
            name
            discipline
            position
          }
          tickets {
            id
            shortId
            title
            description
            fees
            price
            currency
            status
            availableAt
            expiresAt
            maxRegistrations
            participantCount
            minBirthdate
            maxBirthdate
            isVisible
            soldOnKavval
          }
        }
      }
    }
  }
`;

const logEvent = (e) => console.log(e.type, e.detail);

const EventRaces = () => {
  useNjuko();
  const njukoRef = useRef();
  const { loading, error, data } = useQuery(GET_EVENT, {
    variables: { eventShortId: "K4V4L" },
  });
  const event = data?.eventByShortId;

  const setNjukoRoot = useCallback((njukoRoot) => {
    if (njukoRoot !== null) {
      njukoRef.current = njukoRoot;
      console.log("setListener");
      njukoRoot.addEventListener("workflowLoaded", logEvent);
      njukoRoot.addEventListener("cartUpdated", logEvent);
      njukoRoot.addEventListener("connectedRegistrationChanged", logEvent);
      njukoRoot.addEventListener("joinGroupEvent", logEvent);
      njukoRoot.addEventListener("joinTeamEvent", logEvent);
    }
  }, []);

  if (loading) {
    return (
      <PageWrapper>
        <LoadingCentered />
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <GenericNotFoundError tracking={{ pageType: "book/event/offers/error" }} />
      </PageWrapper>
    );
  }

  if (!event) {
    return <NotFoundPage pageType={"book/event/offers/not-found"} />;
  }

  return (
    <PageWrapper logoHref={event.href}>
      <ShoppingCartLayout withSellingPoints rightContent={<ShoppingCartContainer event={event} />}>
        <PageTitle title={t({ id: "page.booking.title", message: "Inscription à la course" })} />

        <div className="njuko-kavval-theme">
          <registration-main
            theme="basic"
            color="blue"
            edition-url="kavval-test-21627459166893"
            locale="fr_FR"
            ref={setNjukoRoot}
          ></registration-main>
        </div>
      </ShoppingCartLayout>
    </PageWrapper>
  );
};

export default EventRaces;
