import { DetailedHTMLProps, ImgHTMLAttributes } from "react";

import { getEmojiCloudinaryURL } from "@kavval/constants";
import { createCloudinaryURL } from "@kavval/formatters";

interface Props extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  name: Parameters<typeof getEmojiCloudinaryURL>[0];
  size?: number;
}

const Emoji = ({ name, size = 36, ...props }: Props) => {
  const emojiCloudinaryURL = getEmojiCloudinaryURL(name);

  return (
    <img
      src={createCloudinaryURL(emojiCloudinaryURL, [`h_${size}`, "q_auto"])}
      height={size}
      width={size}
      alt={`Emoji ${name}`}
      {...props}
    />
  );
};

export default Emoji;
