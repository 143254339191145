import { ActivityEnum } from "./Activity";
import { MultiSportsDisciplineEnum } from "./Discipline";

export const MARATHON_DISTANCE = 42195;
export const HALF_MARATHON_DISTANCE = 21097.5;

export const MultisportsDistances = {
  [MultiSportsDisciplineEnum.Triathlon]: {
    letters: [
      { name: "XS", activities: [400, 10000, 2500] },
      { name: "S", activities: [750, 20000, 5000] },
      { name: "M", activities: [1500, 40000, 10000] },
      { name: "L", activities: [3000, 80000, 20000] },
      { name: "XL", activities: [4000, 120000, 30000] },
    ],
    exactDistances: [
      { name: "Half Ironman", activities: [1900, 90000, 21100] },
      { name: "Ironman", activities: [3800, 180000, 42195] },
    ],
  },
  [MultiSportsDisciplineEnum.CrossTriathlon]: {
    letters: [
      { name: "XS", activities: [250, 5500, 2000] },
      { name: "S", activities: [500, 11000, 4000] },
      { name: "M", activities: [1000, 22000, 8000] },
      { name: "L", activities: [2000, 44000, 16000] },
      { name: "XL", activities: [3000, 66000, 24000] },
    ],
    exactDistances: [],
  },
  [MultiSportsDisciplineEnum.Duathlon]: {
    letters: [
      { name: "XS", activities: [2500, 10000, 1250] },
      { name: "S", activities: [5000, 20000, 2500] },
      { name: "M", activities: [10000, 40000, 5000] },
      { name: "L", activities: [10000, 80000, 10000] },
      { name: "XL", activities: [20000, 120000, 10000] },
      { name: "XXL", activities: [20000, 180000, 20000] },
    ],
    exactDistances: [],
  },
  [MultiSportsDisciplineEnum.Aquathlon]: {
    letters: [
      { name: "XS", activities: [500, 2500] },
      { name: "S", activities: [1000, 5000] },
      { name: "M", activities: [2000, 10000] },
      { name: "L", activities: [3000, 15000] },
      { name: "XL", activities: [4000, 20000] },
    ],
    exactDistances: [],
  },
  [MultiSportsDisciplineEnum.Cyclathlon]: {
    letters: [
      { name: "XXS", activities: [6000, 2500] },
      { name: "XS", activities: [10000, 4000] },
      { name: "S", activities: [20000, 7500] },
      { name: "M", activities: [40000, 15000] },
      { name: "L", activities: [80000, 30000] },
      { name: "XL", activities: [120000, 40000] },
    ],
    exactDistances: [],
  },
} as const;

export const SingleSportsDistances: Record<
  ActivityEnum,
  { ranges: Array<number>; exactDistances?: Record<string, number> }
> = {
  [ActivityEnum.Road]: {
    ranges: [10000, 20000, 40000, 80000],
    exactDistances: {
      "half-marathon": HALF_MARATHON_DISTANCE,
      marathon: MARATHON_DISTANCE,
    },
  },
  [ActivityEnum.Cross]: {
    ranges: [3000, 6000, 9000, 13000],
  },
  [ActivityEnum.Trail]: {
    ranges: [21000, 42000, 80000],
  },
  [ActivityEnum.Nordic]: {
    ranges: [21000, 42000, 80000],
  },
  [ActivityEnum.Cycling]: {
    ranges: [21000, 42000, 80000],
  },
  [ActivityEnum.Walking]: {
    ranges: [21000, 42000, 80000],
  },
  [ActivityEnum.Swimming]: {
    ranges: [21000, 42000, 80000],
  },
  [ActivityEnum.BikeAndRun]: {
    ranges: [21000, 42000, 80000],
  },
  [ActivityEnum.CycloCross]: {
    ranges: [21000, 42000, 80000],
  },
  [ActivityEnum.GravelBiking]: {
    ranges: [21000, 42000, 80000],
  },
  [ActivityEnum.ObstacleRace]: {
    ranges: [21000, 42000, 80000],
  },
  [ActivityEnum.MountainBiking]: {
    ranges: [21000, 42000, 80000],
  },
  [ActivityEnum.Canoe]: {
    ranges: [200, 500, 1000, 5000],
  },
} as const;
