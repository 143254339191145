import { Locale } from "@kavval/lingui-config/locales";

import { GenericDiscipline } from "./Discipline";
import { DistanceUnitEnum } from "./DistanceUnit";
import { EventStatusEnum } from "./EventStatus";

export { DataStatusEnum, default as DataStatusValues } from "./DataStatus";
export * from "./Discipline";
export * from "./DistanceUnit";
export * from "./Currency";
export * from "./Cutoff";
export * from "./Locales";
export * from "./i18n-internal/types";
export * from "./Distances";
export {
  type Activity,
  type ElevationProperties,
  ActivityEnum,
  default as ActivityValues,
} from "./Activity";
export { EventStatusEnum, default as EventStatusValues } from "./EventStatus";
export { UtmbCategory, default as UtmbCategoryValues } from "./UtmbCategory";
export { UtmbEventType, default as UtmbEventTypeValues } from "./UtmbEventType";
export { FfaLabelEnum, default as FfaLabelEnumValues } from "./FfaLabel";
export { InternationalLabelEnum, default as InternationalLabelValues } from "./InternationalLabel";
export { emojiMap, getEmojiCloudinaryURL } from "./Emoji";
export {
  OrganizationStructureEnum,
  default as OrganizationStructureEnumValues,
} from "./OrganizationStructure";
export * from "./resources";

export const getDisciplineTranslationId = (discipline: GenericDiscipline | "semi" | "marathon") =>
  `shared.Disciplines.${discipline}`;

type TranslatedAttributes = "eventSlug" | "eventName" | "city" | "level1" | "level2" | "country";

export type AlgoliaCategoryVariant = `${GenericDiscipline}%${string}`;

export type AlgoliaRaceRecord = {
  _tags: string[];
  tags: string[];
  editionEndDate: string | null;
  editionIsRescheduled: boolean;
  editionStartDate: string | null;
  editionStatus: EventStatusEnum;
  /**
   * @description Attention c'est [Latitude, Longitude]
   */
  coordinates: [number, number] | null;
  image: string | null;
  boosted: boolean;
  countryCode: string | null;
  eventId: string;
  months: number[];
  eventRegistrationUrl: string | null;
  id: string;
  objectID: string;
  raceDate: number | null;
  raceDiscipline: GenericDiscipline;
  raceDisciplineVariants: Array<GenericDiscipline>;
  raceDistance: number | null;
  raceDistanceVariants: Array<number>;
  raceDistanceUnit: DistanceUnitEnum;
  raceDistanceUnitVariants: Array<DistanceUnitEnum>;
  raceCategory: string | null;
  raceCategoryVariants: Array<AlgoliaCategoryVariant | null>;
  raceElevationGain: number;
  raceEndDate: number | null;
  raceItraPoints: number;
  raceMaxPrice: number | null;
  raceMinPrice: number | null;
  raceName: string[] | null;
  placesId: string[] | null;
  placesName: string[] | null;
  level1Id: string | null;
  level2Id: string | null;
  countryId: string | null;
  worldRegionId: string | null;
  eventLastEditionFinisherCount: number | null;
  soldOnKavval: boolean;
  popularity: number | null;
} & Partial<Record<`${TranslatedAttributes}_${Locale}`, string>> &
  Record<Exclude<TranslatedAttributes, "eventSlug" | "eventName">, string | null> &
  Record<"eventSlug" | "eventName", string>;

type EventPreviewAttributes =
  | "objectID"
  | "eventSlug"
  | "eventName"
  | "city"
  | "country"
  | `${"eventSlug" | "eventName" | "city" | "country"}_${Locale}`
  | "image"
  | "boosted"
  | "countryCode"
  | "eventId"
  | "months"
  | "eventRegistrationUrl"
  | "tags"
  | "raceDiscipline"
  | "raceDisciplineVariants"
  | "raceDistance"
  | "raceDistanceVariants"
  | "raceDistanceUnit"
  | "raceDistanceUnitVariants"
  | "raceCategory"
  | "raceCategoryVariants"
  | "editionStartDate"
  | "editionStartDate"
  | "editionEndDate"
  | "editionStatus"
  | "editionIsRescheduled"
  | "soldOnKavval";

export type EventPreviewData = Pick<AlgoliaRaceRecord, EventPreviewAttributes>;

export type GenericPagePreviewData = {
  name: string;
  count?: number | null;
  image?: string | null;
  href: string;
};

type EventPage = EventPreviewData & {
  _pageId: string;
  _pageType: "events";
};

type GenericPage = GenericPagePreviewData & {
  _pageId: string;
  _pageType:
    | "cities"
    | "level1AdminAreas"
    | "level2AdminAreas"
    | "countries"
    | "worldRegions"
    | "places"
    | "tags"
    | "blogPosts"
    | "pages";
};

export type RelatedPage = EventPage | GenericPage;
