import { useEffect } from "react";

let isNjukoLoaded = false;

const loadNjuko = () => {
  if (isNjukoLoaded) return;

  isNjukoLoaded = true;

  const script = document.createElement("script");

  script.src = "https://developer.njuko.com/elements/njuko-front-element.js";
  script.async = true;

  document.body.appendChild(script);
};

const useNjuko = () => {
  useEffect(() => loadNjuko(), []);
};

export default useNjuko;
