import { ComponentProps } from "react";

import { GenericDiscipline } from "@kavval/constants";

import Emoji from "../Emoji";

export const disciplineEmojis: Partial<
  Record<GenericDiscipline, ComponentProps<typeof Emoji>["name"]>
> = {
  road: "road",
  trail: "snow-capped-mountain",
  nordic: "nordic-walking-sticks-2",
  walking: "nordic-walking-sticks-2",
  obstacle_race: "construction",
} as const;

interface Props extends Omit<ComponentProps<typeof Emoji>, "name"> {
  name: keyof typeof disciplineEmojis;
}

const DisciplineEmoji = ({ name, size = 18, ...props }: Props) => {
  const emoji = disciplineEmojis[name];

  return emoji ? <Emoji {...props} name={emoji} size={size} /> : null;
};

export default DisciplineEmoji;
